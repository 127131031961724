/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable unused-imports/no-unused-vars */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer
} from '@mui/x-data-grid';
import * as uuid from 'uuid';
import { getModelNumberOptions } from 'shared/utils/helperFunctions';
import React, { useEffect, useMemo, useState } from 'react';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { TooltipChild } from 'features/RemoteManagement/SiteDashboard/Devices/components/common';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const initialRows: GridRowsProp = [];

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

interface Props {
  manualSelectedDevices: GridRowModel[];
  setManualSelectedDevices: (devices: GridRowModel[]) => void;
  restrictedMacAddresses: string[];
  deviceTypeFilter?: string[];
  selectedUnitType?: number;
  maxStationCountsForUnit?: { [key: string]: number };
  currentStationCountsForUnit?: { [key: string]: number };
  devicesResetTrigger?: number;
  setIsAtDeviceLimit?: (isAtLimit: boolean) => void;
}

const AddDeviceManuallyDataGrid = ({
  manualSelectedDevices,
  setManualSelectedDevices,
  restrictedMacAddresses,
  deviceTypeFilter,
  selectedUnitType,
  maxStationCountsForUnit,
  currentStationCountsForUnit,
  devicesResetTrigger,
  setIsAtDeviceLimit: parentSetIsAtDeviceLimit
}: Props) => {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [isBatchAddDialogOpen, setIsBatchAddDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [batchType, setBatchType] = useState('');
  const [batchModel, setBatchModel] = useState('');
  const [batchQuantity, setBatchQuantity] = useState(1);
  const [isAtDeviceLimit, setInternalIsAtDeviceLimit] = useState(false);
  const [isDeviceTypeAllowed, setIsDeviceTypeAllowed] = useState(true);
  const selectedDeviceIds = useMemo(() => manualSelectedDevices.map((device) => device.id), [manualSelectedDevices]);
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);

  const setCurrentRows = () => {
    if (manualSelectedDevices.length > 0) {
      return manualSelectedDevices;
    }
    return initialRows;
  };

  const updateIsAtDeviceLimit = (limitReached: boolean) => {
    setInternalIsAtDeviceLimit(limitReached);
    if (parentSetIsAtDeviceLimit) {
      parentSetIsAtDeviceLimit(limitReached);
    }
  };
  const [rows, setRows] = useState(setCurrentRows());

  useEffect(() => {
    const editing = Object.values(rowModesModel).some((row) => row.mode === GridRowModes.Edit);
    setIsEditing(editing);
  }, [rowModesModel]);

  useEffect(() => {
    setIsEditing(false);
    setRowModesModel({});
  }, [devicesResetTrigger]);

  const disallowedDeviceTypes: { [key: number]: string[] } = {
    1: ['Video Master Station', 'Tenant Station', 'Video Entrance Station'],
    2: [
      'Video Master Station',
      'Sub Master Station',
      'Tenant Station',
      'Video Guard Station',
      'Video Door Station',
      'Audio Door Station',
      'Emergency Station',
      'Stainless Steel Audio Door Station',
      'Mullion Video Door Station',
      'Plastic Video Door Station'
    ],
    3: ['Tenant Station', 'Video Entrance Station'],
    4: ['Video Master Station', 'Video Guard Station', 'Video Entrance Station'],
    5: ['Tenant Station', 'Video Guard Station', 'Video Entrance Station'],
    6: ['Video Master Station', 'Sub Master Station', 'Tenant Station', 'Video Guard Station', 'Video Entrance Station']
  };

  const isDeviceTypeDisallowed = (deviceTypeFilter: string[], disallowedTypes: string[]): boolean => {
    return disallowedTypes.some((type) => deviceTypeFilter.includes(type));
  };

  useEffect(() => {
    if (deviceTypeFilter !== undefined && selectedUnitType !== undefined) {
      const disallowedTypes = disallowedDeviceTypes[selectedUnitType] || [];
      const isDisallowed = deviceTypeFilter && isDeviceTypeDisallowed(deviceTypeFilter, disallowedTypes);

      setIsDeviceTypeAllowed(!isDisallowed);
    }
  }, [selectedUnitType, deviceTypeFilter]);

  // Removing this seems to fix the issue of rows disappearing when user checks/unchecks the "Select All" checkbox
  // useEffect(() => {
  //   setRows(setCurrentRows());
  // }, [manualSelectedDevices]);

  const deviceTypes = [
    { value: 'IX-MV7', label: 'Master Station' },
    { value: 'IX-RS', label: 'Sub Master Station' },
    { value: 'IX-DV', label: 'Video Door Station' },
    { value: 'IX-SSA', label: 'Audio Door Station' },
    { value: 'IXW-MA', label: 'I/O Network Adaptor' },
    { value: 'IXG-DM7', label: 'Entrance Station' },
    { value: 'IXG-MK', label: 'Guard Station' },
    { value: 'IXG-2C7', label: 'Tenant Station' },
    { value: 'IXGW-LC', label: 'Network Controller' },
    { value: 'IXGW-GW', label: 'Gateway' }
  ];
  let filteredDeviceTypes = deviceTypes;
  if (deviceTypeFilter) {
    filteredDeviceTypes = deviceTypes.filter((type) => deviceTypeFilter.includes(type.label));
  }

  const validateMacAddress = (value: string, rowId: string) => {
    if (!value) {
      return 'MAC Address is required';
    }
    if (value.length < 17) {
      return 'MAC Address must be 17 characters long';
    }
    if (value.length > 17) {
      return 'MAC Address must be 17 characters long';
    }
    const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
    if (!macAddressRegex.test(value)) {
      return 'MAC Address must be in the format XX:XX:XX:XX:XX:XX';
    }

    if (restrictedMacAddresses.includes(value)) {
      return 'MAC Address is already in use';
    }

    // Skip checking the current row's MAC address
    const isMacAddressDuplicate = rows.some((row) => row.mac_addr === value && row.id !== rowId);
    if (isMacAddressDuplicate) {
      return 'MAC Address must be unique';
    }

    return null;
  };

  const checkDeviceLimit = (rows: GridRowModel[]) => {
    const isDeviceTypeFilterArray = deviceTypeFilter && Array.isArray(deviceTypeFilter);
    let hasLimitReached = false;
    switch (isDeviceTypeFilterArray ? deviceTypeFilter[0] : deviceTypeFilter) {
      case 'Video Master Station':
      case 'Sub Master Station':
      case 'Tenant Station':
      case 'Guard Station':
        if (
          maxStationCountsForUnit &&
          currentStationCountsForUnit &&
          rows.length > maxStationCountsForUnit.answeringStations - currentStationCountsForUnit.answeringStations
        ) {
          hasLimitReached = true;
        }
        break;
      case 'Video Door Station':
      case 'Audio Door Station':
      case 'Emergency Station':
      case 'Stainless Steel Audio Door Station':
      case 'Mullion Video Door Station':
      case 'Plastic Video Door Station':
        if (
          maxStationCountsForUnit &&
          currentStationCountsForUnit &&
          rows.length > maxStationCountsForUnit.doorStations - currentStationCountsForUnit.doorStations
        ) {
          hasLimitReached = true;
        }
        break;
      case 'Entrance Station':
        if (
          maxStationCountsForUnit &&
          currentStationCountsForUnit &&
          rows.length > maxStationCountsForUnit.entranceStations - currentStationCountsForUnit.entranceStations
        ) {
          hasLimitReached = true;
        }
        break;
      default:
        break;
    }

    updateIsAtDeviceLimit(hasLimitReached);
  };

  const MacAddressEditInputCell = (props: GridRenderEditCellParams) => {
    const { error: errorProp } = props;
    const errorMessage = validateMacAddress(props.value as string, props.id as string);
    const hasError = Boolean(errorProp || errorMessage);

    return (
      <Box>
        <Tooltip open={hasError && props.hasFocus} title={errorMessage}>
          <TooltipChild {...props} error={hasError} ref={props.inputRef} />
        </Tooltip>
        {hasError && !props.hasFocus && (
          <Typography variant="caption" color="error">
            {errorMessage}
          </Typography>
        )}
      </Box>
    );
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    checkDeviceLimit([...rows]);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
    checkDeviceLimit(updatedRows);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const errorMessage = validateMacAddress(newRow.mac_addr, newRow.id);
    if (errorMessage) {
      newRow.error = errorMessage;
      return { ...newRow, isNew: true };
    }

    const updatedRow = { ...newRow, isNew: false, error: undefined };
    const updatedRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
    setRows(updatedRows);
    checkDeviceLimit(updatedRows);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleManualDeviceSelection = (newSelection: GridRowId[]) => {
    const selectedRows = rows.filter((row) => newSelection.includes(row.id));
    setManualSelectedDevices(selectedRows);
  };

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;
    const handleAddDevice = () => {
      const id = uuid.v4();
      setRows((oldRows) => [...oldRows, { id, mac_addr: '00:0B:AA:', device_type: '', model_number: '', isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, isNew: true }
      }));
    };

    const handleBatchAddDevice = () => {
      isBatchAddDialogOpen ? setIsBatchAddDialogOpen(false) : setIsBatchAddDialogOpen(true);
    };

    return (
      <GridToolbarContainer>
        <Tooltip
          title={
            isEditing
              ? 'Only one device may be edited at a time'
              : '' || isAtDeviceLimit
              ? 'The maximum number of devices for this unit type has been reached'
              : ''
          }
        >
          <span>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddDevice}
              disabled={isEditing || isAtDeviceLimit || !isDeviceTypeAllowed}
            >
              Add Device
            </Button>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleBatchAddDevice}
              disabled={isEditing || isAtDeviceLimit || !isDeviceTypeAllowed}
            >
              Batch Add Devices
            </Button>
          </span>
        </Tooltip>
      </GridToolbarContainer>
    );
  }

  const columns: GridColDef[] = [
    {
      field: 'mac_addr',
      headerName: 'MAC Address',
      width: 180,
      editable: true,
      valueParser(value: any) {
        let formattedMac = value.replace(/[^0-9A-Fa-f]/g, '').toUpperCase();
        if (formattedMac.length > 12) formattedMac = formattedMac.slice(0, 12);
        return formattedMac.match(/.{1,2}/g)?.join(':') || formattedMac;
      },
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const errorMessage = validateMacAddress(params.props.value as string, params.props.id);
        return { ...params.props, error: errorMessage };
      },
      renderEditCell: MacAddressEditInputCell
    },
    {
      field: 'device_type',
      headerName: 'Device Type',
      width: 220,
      editable: true,
      type: 'singleSelect',
      valueOptions: filteredDeviceTypes
    },
    {
      field: 'model_number',
      headerName: 'Model Number',
      width: 250,
      editable: true,
      type: 'singleSelect',
      valueOptions: (params) => getModelNumberOptions(params.row.device_type),
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return { ...params.props };
      },
      renderCell(params) {
        const modelName: string = params.value === 'IXG-DM7-HID(A)' ? 'IXG-DM7-HID' : params.value;
        const imgUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${modelName}.png`;
        if (params.value) {
          return (
            <MenuItem sx={{ width: '100%' }}>
              {params.value ? (
                <ListItemIcon sx={styles.stationIcons}>
                  <img src={imgUrl} alt="" />
                </ListItemIcon>
              ) : (
                <></>
              )}
              <ListItemText primary={params.value} />
            </MenuItem>
          );
        }
      },
      renderEditCell(params) {
        const id = `model_number_${params.row.id}`;
        return (
          <Select
            variant={'outlined'}
            SelectDisplayProps={{
              style: { display: 'flex', alignItems: 'center' }
            }}
            value={params.value}
            id={id}
            onChange={async (event) => {
              await params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: event.target.value
              });
            }}
            inputProps={{
              'aria-label': 'Select Model Number'
            }}
            fullWidth
            notched
            placeholder="Select Model Number"
            disabled={params.row.device_type === ''}
          >
            {getModelNumberOptions(params.row.device_type).map((option) => {
              const modelName: string = option === 'IXG-DM7-HID(A)' ? 'IXG-DM7-HID' : option;
              const imgUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${modelName}.png`;
              return (
                <MenuItem key={option} value={option} disableGutters sx={{ width: '100%' }}>
                  <ListItemIcon sx={styles.stationIcons}>
                    <img src={imgUrl} alt="" />
                  </ListItemIcon>
                  <ListItemText primary={option} />
                </MenuItem>
              );
            })}
          </Select>
        );
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            disabled={isEditing}
          />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />
        ];
      }
    }
  ];

  const sanitizeQuantityInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value.length > 1) event.target.value = event.target.value.replace(/^0+/, ''); // trim any leading zeros (eg. 01, 001, etc)
    if (Number(event.target.value) < 1)
      // disallow negative values
      event.target.value = '1';
    setBatchQuantity(Number(event.target.value));
  };

  const disallowSpecialCharPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const disallowedKeysRegex = /[.+-]/;
    const shouldPrevent = disallowedKeysRegex.test(event.key);
    if (shouldPrevent) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Box
        sx={{
          height: 550,
          width: '100%',
          '& .actions': {
            color: 'text.secondary'
          },
          '& .textPrimary': {
            color: 'text.primary'
          }
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.id}
          editMode="row"
          checkboxSelection
          disableRowSelectionOnClick
          rowModesModel={rowModesModel}
          rowSelectionModel={selectedDeviceIds}
          onRowModesModelChange={handleRowModesModelChange}
          onRowSelectionModelChange={handleManualDeviceSelection}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar,
            noRowsOverlay: () => {
              if (!isDeviceTypeAllowed) {
                return (
                  <Box sx={styles.noStation}>
                    <Typography variant="h6">The selected unit does not support this device type.</Typography>
                    <Typography variant="body1">Select a different device type or unit type to add devices.</Typography>
                  </Box>
                );
              }

              return (
                <Box sx={styles.noStation}>
                  <Typography variant="h6">No stations have been added yet.</Typography>
                  <Typography variant="body1">
                    Click the <strong>Add Device</strong> button to start adding stations.
                  </Typography>
                </Box>
              );
            }
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel }
          }}
          isRowSelectable={(params) => {
            const { isNew, device_type, model_number, mac_addr } = params.row;
            const isDeviceTypeValid = device_type !== '';
            const isModelNumberValid = model_number !== '';
            const isMacAddrValid = mac_addr !== '';
            const isGWSelected = manualSelectedDevices.some(
              (device) => device.device_type === 'IXGW-GW' || device.device_type === 'IXGW-TGW'
            );

            const isGateway = device_type === 'IXGW-GW' || device_type === 'IXGW-TGW';
            const isIOAdaptor = device_type === 'IXW-MA';
            const isLiftControl = device_type === 'IXGW-LC' || device_type === 'IXGW-LC-RY20';
            const isDifferentMacAddr = manualSelectedDevices.some((device) => device.mac_addr !== mac_addr);
            const hasGatewayInSite = Object.entries(deviceList).some(
              ([, device]) => device.basicInfo.deviceType === 18
            );

            // If site already has devices and one is a Gateway, disallow adding any other gateways
            if (hasGatewayInSite && isGateway) {
              return false;
            }

            // If a Gateway is already selected, disallow adding any other gateways
            if (isGateway && isDifferentMacAddr) {
              return !isNew && isDeviceTypeValid && isModelNumberValid && isMacAddrValid && !isGWSelected;
            }

            // If there are already 500 IO Adaptors, disallow adding any more
            if (isIOAdaptor) {
              const ioAdaptorCount = manualSelectedDevices.filter((device) => device.device_type === 'IXW-MA').length;
              return !isNew && isDeviceTypeValid && isModelNumberValid && isMacAddrValid && ioAdaptorCount < 500;
            }

            // If there are already 16 Lift Controls, disallow adding any more
            if (isLiftControl) {
              const liftControlCount = manualSelectedDevices.filter(
                (device) => device.device_type === 'IXGW-LC' || device.device_type === 'IXGW-LC-RY20'
              ).length;
              return !isNew && isDeviceTypeValid && isModelNumberValid && isMacAddrValid && liftControlCount < 16;
            }

            return !isNew && isDeviceTypeValid && isModelNumberValid && isMacAddrValid;
          }}
        />
      </Box>
      <Dialog
        open={isBatchAddDialogOpen}
        onClose={() => {
          setIsBatchAddDialogOpen(false);
        }}
        maxWidth="md"
      >
        <DialogTitle>Batch Add Devices</DialogTitle>
        <DialogContent>
          <DialogContentText>Select a device type, model number, and enter a quantity below.</DialogContentText>
          <Box sx={styles.mtmb2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="device-type-label">Select Device Type</InputLabel>
                  <Select
                    variant={'outlined'}
                    labelId="device-type-label"
                    id="device-type"
                    value={batchType}
                    onChange={(event) => {
                      setBatchType(event.target.value as string);
                    }}
                    inputProps={{
                      'aria-label': 'Select Device Type'
                    }}
                    fullWidth
                    label="Select Device Type"
                  >
                    {filteredDeviceTypes.map((option) => {
                      return (
                        <MenuItem key={option.value} value={option.value}>
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="model-number-batch-label">Select Model Number</InputLabel>
                  <Select
                    variant={'outlined'}
                    labelId="model-number-batch-label"
                    id="model-number-batch"
                    SelectDisplayProps={{
                      style: { display: 'flex', alignItems: 'center' }
                    }}
                    value={batchModel}
                    onChange={(event) => {
                      setBatchModel(event.target.value as string);
                    }}
                    inputProps={{
                      'aria-label': 'Select Model Number'
                    }}
                    label="Select Model Number"
                    fullWidth
                    disabled={batchType === ''}
                  >
                    {getModelNumberOptions(batchType).map((option) => {
                      const model: string = option === 'IXG-DM7-HID(A)' ? 'IXG-DM7-HID' : option;
                      const imgUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${model}.png`;
                      return (
                        <MenuItem key={option} value={option} sx={styles.width100}>
                          <ListItemIcon sx={styles.stationIcons}>
                            <img src={imgUrl} alt="" />
                          </ListItemIcon>
                          <ListItemText primary={option} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Quantity"
                  type="number"
                  value={batchQuantity}
                  onKeyDown={(event) => disallowSpecialCharPress(event)}
                  onChange={(event) => sanitizeQuantityInput(event)}
                  fullWidth
                  disabled={batchType === '' || batchModel === ''}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsBatchAddDialogOpen(false)}>Close</Button>
          <Button
            onClick={() => {
              const newRows: {
                id: string;
                mac_addr: string;
                device_type: string;
                model_number: string;
                isNew: boolean;
              }[] = [];
              for (let i = 0; i < batchQuantity; i++) {
                const id = uuid.v4();
                newRows.push({
                  id,
                  mac_addr: '00:0B:AA:',
                  device_type: batchType,
                  model_number: batchModel,
                  isNew: true
                });
              }
              setRows((oldRows) => [...oldRows, ...newRows]);
              setBatchModel('');
              setBatchType('');
              setBatchQuantity(1);
              setIsBatchAddDialogOpen(false);
            }}
            disabled={batchType === '' || batchModel === '' || batchQuantity === 0}
          >
            Add Devices
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  stationIcons: {
    minWidth: '80px !important',
    display: 'flex',
    justifyContent: 'center'
  },
  mtmb2: {
    mt: 2,
    mb: 2
  },

  width100: {
    width: '100%'
  },
  noStation: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
    p: 3
  }
};

export default AddDeviceManuallyDataGrid;
