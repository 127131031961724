import { Container, Typography } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { RootState } from 'store';

interface HeaderProps {
  siteName: string;
  awsPropertyId: number;
  selectedDevice: string;
  stationNumber?: number;
}
export const Header: FC<HeaderProps> = ({ siteName, awsPropertyId, selectedDevice, stationNumber }) => {
  const device = useSelector((state: RootState) => state.devices.DeviceList[selectedDevice]);
  const imageUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${getDeviceModelNumberFromModelType(
    device.basicInfo.deviceModel,
    device.basicInfo.deviceType
  )}.png`;
  return (
    <Container sx={{ marginLeft: '0', display: 'flex', mb: '150px' }}>
      <img src={imageUrl} alt="Device Image" />

      <Container sx={{ m: 0 }}>
        <Typography>
          Site:{siteName} / Site ID:{awsPropertyId} / station Number:{stationNumber}{' '}
        </Typography>
        <Typography variant="h4">
          {getDeviceModelNumberFromModelType(device.basicInfo.deviceModel, device.basicInfo.deviceType)}
        </Typography>
      </Container>
    </Container>
  );
};
