/**
 * Component that combines the Network and Device Info pages into one DataGrid.
 */
import React, { useEffect } from 'react';
import 'styles/frontshine.css';
import { DeviceList, IDevice, setDeviceNetworkSettings } from 'store/slices/devicesSlice';
import { isValidIPV4Address } from 'features/RemoteManagement/SiteDashboard/utils';
import {
  DataGrid,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridInitialState,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridRowsProp,
  GridSingleSelectColDef,
  GridValidRowModel,
  GridValueFormatterParams,
  useGridApiRef
} from '@mui/x-data-grid';
import { formatAsIPAddress, getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container } from '@mui/material';
import Spinner from 'features/SimBilling/Components/UiParts/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import TextInputDataGridCell from 'shared/components/TextInputDataGridCell';
import { fetchGatewayCommand, IDeviceInfoPayload, IGatewayInfoPayload } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import {
  useGetDeviceListWithSitePublicIdQuery,
  useBatchUpdateDevicesMutation,
  useHandleGatewayCommandMutation,
  useUpdateDeviceMutation
} from 'services/aiphoneCloud';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { RegexIpV4, RegexSubnetMask, RegexIPV4AndDNSGateway } from 'features/RemoteManagement/Types';
import { IDevicesToUpdate } from 'features/RemoteManagement/NewSiteWizard/steps/AddDevices/AddDevices';
import { LoadingProgressStepCounter } from 'features/RemoteManagement/SiteDashboard/Devices/components/LoadingProgressStepCounter';

const MAX_LENGTH = 24;

const DEFAULT_IP_ADDRESS = '0.0.0.0';

const VALID_STATION_NAME_REGEX = /^[A-Za-z0-9+\-_?./()%$@! ]{1,24}$/;

const testIfValidStationName = (stationName: string): boolean => {
  // /^[A-Za-z0-9+\-_?./()%$@! ]{1,24}$/: This regex ensures that:
  // ^ and $: The string starts and ends respectively.
  // [A-Za-z0-9+\-_?./()%$@! ]: These are the valid characters. This includes:
  // Alphanumeric characters: A-Za-z0-9
  // Special characters listed: + - _ ? . / ( ) % $ @ !
  // Space:
  // {1,24}: The length of the string should be between 1 and 24 characters, inclusive.
  //
  // See: https://github.com/AiphoneCorporation/aiphone-cloud-app/pull/1205?notification_referrer_id=NT_kwDOCp9uK7UxMjY3MDU5MzkyMzoxNzgyMjA1ODc&notifications_query=is%3Aunread#issuecomment-2387253787
  return VALID_STATION_NAME_REGEX.test(stationName);
};

const testIfValidRegexIPV4 = (ipAddress: string) => {
  // Check if this starts with zero

  const octets = ipAddress.split('.');
  if (octets[0] === '0') {
    return false;
  }
  if (ipAddress === DEFAULT_IP_ADDRESS) {
    return false;
  }
  const RegexTester = new RegExp(RegexIpV4);
  return RegexTester.test(ipAddress);
};

const testIfValidRegexSubnetMask = (subnetMask: string) => {
  if (subnetMask === DEFAULT_IP_ADDRESS) {
    return false;
  }
  const RegexTester = new RegExp(RegexSubnetMask);
  return RegexTester.test(subnetMask);
};

const testIfValidRegexIPV4OrDNSGateway = (gateway: string) => {
  if (gateway === DEFAULT_IP_ADDRESS) {
    return false;
  }
  const RegexTester = new RegExp(RegexIPV4AndDNSGateway);
  return RegexTester.test(gateway);
};

// Track the state of the button for the Association
type DeviceAssociationStatus = 'SKIP' | 'ASSOCIATE' | 'TBD' | 'SAVE';

// Added this for more consistency in the code
enum DeviceAssociationStatusEnum {
  SKIP = 'SKIP',
  ASSOCIATE = 'ASSOCIATE',
  TBD = 'TBD',
  SAVE = 'SAVE'
}

// Validate the initial state of the device
// We do not need to check for duplicate station numbers because this is the initial state, so there shall be no
// conflicts

export interface DeviceConfigurationGridProps {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

const DeviceConfigurationGrid = (props: DeviceConfigurationGridProps) => {
  const site = useSelector((state: RootState) => state.site);
  const awsPropertyId = site?.siteInfo?.awsPropertyId;
  const gateway = useSelector((state: RootState) => state.devices.DeviceList[site.siteInfo.registeredGatewayPublicId]);
  const gatewayLoading = useSelector((state: RootState) => state.gateway.loading);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const devices = useSelector((state: RootState) => state.devices).DeviceList;
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [handleGatewayCommand, { isError: gwCommandError, isLoading: gwLoading }] = useHandleGatewayCommandMutation();
  const dataGridRef = React.useRef<HTMLDivElement>(null);
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const [batchUpdateDevices] = useBatchUpdateDevicesMutation();
  const [error, setError] = React.useState<string | null>(null);
  const [updateDevice] = useUpdateDeviceMutation();
  const [canSubmit, setCanSubmit] = React.useState<boolean>(false);
  const [registrationStatusMessage, setRegistrationStatusMessage] = React.useState<string | undefined>(undefined);
  const [countTotalSteps, setCountTotalSteps] = React.useState<number>(0);
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [initialState, setInitialState] = React.useState<GridInitialState>({});
  const { refetch } = useGetDeviceListWithSitePublicIdQuery({
    sitePublicId: site.siteInfo.publicId,
    qty: -1,
    page: 0
  });

  const { t } = useTranslation();

  // Fetch devices after they have been added to the unit
  // Logic updated to handle multi-tenant selection in the wizard
  useEffect(() => {
    refetch();
  }, [refetch]);

  const formatDevices = (devices: DeviceList) => {
    const gateway = site.siteInfo.registeredGatewayPublicId;
    const gatewayDevice = devices[gateway];

    const getStartingIPAddress = (device: IDevice | undefined): number => {
      if (device?.networkSettings?.ipV4Address) {
        const lastOctet = device.networkSettings.ipV4Address.split('.').slice(-1)[0];
        return parseInt(lastOctet, 10);
      }
      return 10; // Default to 10 if the IP address is not available
    };

    const getFirstThreeOctets = (device: IDevice | undefined): string => {
      if (device?.networkSettings?.ipV4Address) {
        return device.networkSettings.ipV4Address.split('.').slice(0, 3).join('.');
      }
      return '192.168.1'; // Default to '192.168.1' if the IP address is not available
    };

    const startingIPAddress = getStartingIPAddress(gatewayDevice);
    const firstThree = getFirstThreeOctets(gatewayDevice);

    if (Object.keys(devices).length === 0) {
      return [] as GridRowsProp;
    }

    return Object.keys(devices).map((deviceId, index) => {
      const device = devices[deviceId];
      const ipAddress = device?.networkSettings?.ipV4Address;
      const subnetMask = device?.networkSettings?.subnetMask;
      const defaultGateway = device?.networkSettings?.ipV4DefaultGateway;

      return {
        mac_addr: device?.basicInfo?.macAddress ?? '',
        model_number:
          getDeviceModelNumberFromModelType(device?.basicInfo?.deviceModel, device?.basicInfo?.deviceType) ?? '',
        // Adding the device image based on the device model
        device_image: `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${getDeviceModelNumberFromModelType(
          device?.basicInfo?.deviceModel,
          device?.basicInfo?.deviceType
        )}.png`,
        station_name: device?.basicInfo?.stationName ?? '',
        station_number: device?.basicInfo?.stationNumber ?? '',
        ip_address:
          ipAddress && ipAddress !== DEFAULT_IP_ADDRESS && ipAddress !== '192.168.1.160'
            ? ipAddress
            : `${firstThree}.${startingIPAddress + index + 1}`,
        subnet_mask:
          subnetMask && subnetMask !== DEFAULT_IP_ADDRESS
            ? subnetMask
            : gatewayDevice?.networkSettings?.subnetMask ?? '255.255.255.0',
        default_gateway:
          defaultGateway && defaultGateway !== DEFAULT_IP_ADDRESS
            ? defaultGateway
            : gatewayDevice?.networkSettings?.ipV4DefaultGateway ?? '192.168.1.1',
        dns: gatewayDevice?.networkSettings?.ipV4PrimaryDns ?? '8.8.8.8',
        association_status: DeviceAssociationStatusEnum.TBD as DeviceAssociationStatus,
        // These are hidden columns that will not be rendered for the user. They are needed internally, though.
        id: device.publicId,
        is_valid: false,
        firmware_version: device?.basicInfo?.firmwareVersion ?? '',
        device_type: device?.basicInfo?.deviceType ?? 0
      };
    }) as GridRowsProp;
  };

  /**
   * Checks if the row should be skipped based on its association status.
   *
   * @param {GridPreProcessEditCellProps} params - The parameters for the cell being processed.
   * @param {any} apiRef - The reference to the DataGrid API.
   * @returns {boolean} - Returns true if the row's association status is 'SKIP', otherwise false.
   */
  const checkIfShouldSkipRow = (params: GridPreProcessEditCellProps, apiRef: any): boolean => {
    const row = apiRef.current?.getRow(params.id);
    const associationStatus = row?.association_status;
    return associationStatus === DeviceAssociationStatusEnum.SKIP;
  };

  const preProcessDefaultGateway = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      const validatedStatus = checkIfShouldSkipRow(params, apiRef);
      if (validatedStatus) {
        return { ...params.props, error: undefined };
      }
      if (!testIfValidRegexIPV4OrDNSGateway(params.props.value as string)) {
        return { ...params.props, error: t('Invalid_Default_Gateway') };
      } else {
        return { ...params.props, error: undefined };
      }
    },
    [apiRef, t]
  );

  const preProcessIPAddress = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      const validatedStatus = checkIfShouldSkipRow(params, apiRef);
      if (validatedStatus) {
        return { ...params.props, error: undefined };
      }

      // There cannot be duplicate IP addresses and these must be valid according to regex
      if (!testIfValidRegexIPV4(params.props.value as string)) {
        // Replacing with the exact error message as the DeviceNetworkInfoTabContent
        return { ...params.props, error: t('IpV4Address_Error_Invalid') };
      } else {
        // Check if the IP address is unique
        const rows = apiRef.current?.getRowModels();
        let isUnique = true;
        for (const value of rows.values()) {
          if (value.ip_address === params.props.value && value.id !== params.id) {
            isUnique = false;
            break;
          }
        }
        return { ...params.props, error: !isUnique ? t('IP_Address_Must_be_Unique') : undefined };
      }
    },
    [t]
  );

  const preProcessSubnetMask = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      const validatedStatus = checkIfShouldSkipRow(params, apiRef);
      if (validatedStatus) {
        return { ...params.props, error: undefined };
      }

      if (!testIfValidRegexSubnetMask(params.props.value as string)) {
        // Replacing with the exact error message as the DeviceNetworkInfoTabContent
        return { ...params.props, error: t('SubnetMask_Error_Invalid') };
      } else {
        return { ...params.props, error: undefined };
      }
    },
    [t]
  );

  const preProcessDNS = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      const validatedStatus = checkIfShouldSkipRow(params, apiRef);
      if (validatedStatus) {
        return { ...params.props, error: undefined };
      }

      if (!testIfValidRegexIPV4OrDNSGateway(params.props.value as string)) {
        return { ...params.props, error: t('Invalid_DNS') };
      } else {
        return { ...params.props, error: undefined };
      }
    },
    [t]
  );

  const preProcessStationNumber = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      const validatedStatus = checkIfShouldSkipRow(params, apiRef);
      if (validatedStatus) {
        return { ...params.props, error: undefined };
      }

      const { props, id } = params;

      let value = props.value as string;
      let error = undefined;
      if (value.length < 3) {
        error = t('Minimum_3_Digits');
      } else if (value.length > MAX_LENGTH) {
        value = value.substring(0, MAX_LENGTH);
        error = t('Maximum_24_characters');
      }

      // Check if the string can be a number
      if (isNaN(Number(value))) {
        return { ...props, value, error: t('Station_Number_must_be_a_number') };
      }

      const rows = apiRef.current?.getRowModels();

      let isUnique = true;

      for (const value of rows.values()) {
        if (value.station_number === props.value && value.id !== id) {
          isUnique = false;
          break;
        }
      }

      if (!error && !isUnique) {
        error = t('Must_be_unique');
      }

      return { ...props, value, error };
    },
    [t]
  );

  const preProcessStationName = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      const validatedStatus = checkIfShouldSkipRow(params, apiRef);
      if (validatedStatus) {
        return { ...params.props, error: undefined };
      }

      const { props } = params;
      let value = props.value as string;
      let error;
      if (value.length > MAX_LENGTH) {
        value = value.substring(0, MAX_LENGTH);
        error = t('Maximum_24_characters');
      } else if (value.length === 0) {
        error = t('Station_Name_required');
      }

      // Check that the station name is valid
      if (!testIfValidStationName(value)) {
        // Test the regex pattern for valid station name, and set error if found
        error = t('Invalid_Station_Name');
      }

      // Check that the station name is unique
      const rows = apiRef.current?.getRowModels();
      let isUnique = true;
      for (const value of rows.values()) {
        if (value.station_name === props.value && value.id !== params.id) {
          isUnique = false;
          break;
        }
      }
      if (!error && !isUnique) {
        error = t('Must_be_unique');
      }

      return { ...props, value, error };
    },
    [t]
  );

  const handleCellClick = React.useCallback((params: GridCellParams, event: React.MouseEvent) => {
    // Ignore portal
    if ((event.target as any).nodeType === 1 && !event.currentTarget.contains(event.target as Element)) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View }
              }),
              {}
            )
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit }
        }
      };
    });
  }, []);

  const preProcessSelectAssociationStatus = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      const { props } = params;
      const value = params.props.value as string;
      // Check if the value is not 'TBD'
      if (value === 'TBD') {
        return { ...props, error: t('Must_Select_Option') };
      } else {
        return { ...props, error: undefined };
      }
    },
    [t]
  );

  const handleCellModesModelChange = (newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  };

  /**
   * @function isCellOpenForRow
   * @description Check if any cell is open for a row
   */
  const isCellOpenForRow = (rowId: string) => {
    const state = apiRef.current.state;
    const row = state.editRows[rowId];
    return row ? Object.values(row).some((cell) => cell.isEditing) : false;
  };

  const isValidDevice = React.useCallback(
    (device: GridValidRowModel): boolean => {
      const rows = apiRef.current?.getRowModels();

      const isUnique = (field: string, value: string) => {
        return !Object.values(rows).some((row) => row[field] === value && row.id !== device.id);
      };

      if (device.association_status === DeviceAssociationStatusEnum.SKIP) {
        return true;
      }

      return (
        testIfValidRegexIPV4(device.ip_address) &&
        testIfValidRegexSubnetMask(device.subnet_mask) &&
        isValidIPV4Address(device.default_gateway) &&
        testIfValidRegexIPV4OrDNSGateway(device.dns) &&
        // Check if the station number is actually a number
        !isNaN(Number(device.station_number)) &&
        // Check if the station number is at least 3 digits and less than 25 unique
        device.station_number.length >= 3 &&
        device.station_number.length <= MAX_LENGTH &&
        isUnique('station_number', device.station_number) &&
        // Check if the station name is between 1 and 24 characters and unique
        device.station_name.length > 0 &&
        device.station_name.length <= MAX_LENGTH &&
        isUnique('station_name', device.station_name) &&
        // Check if the IP address is unique
        isUnique('ip_address', device.ip_address) &&
        // Check if the association_status is not 'TBD'
        device.association_status !== DeviceAssociationStatusEnum.SKIP
      );
    },
    [apiRef]
  );

  // Associate with Static IP configuration - associate one device at a time
  const staticAssociation = React.useCallback(
    async (device: any) => {
      setIsLoading(true);
      // Pass in the device row data
      const deviceMacAddress = device.mac_addr;

      let whichId = site.siteInfo.systemId ? site.siteInfo.systemId : gateway.basicInfo.adminId;
      let whichPassword = site.siteInfo.systemPassword ? site.siteInfo.systemPassword : gateway.basicInfo.adminPass;
      const isGatewayFirstSync = gateway?.lastSyncedOn === null;
      if (isGatewayFirstSync && !site.siteInfo.systemId && !site.siteInfo.systemPassword) {
        whichId = 'admin';
        whichPassword = 'admin';
      }

      // Get the device from the deviceList slice
      const dataDevice = devices[deviceMacAddress];

      const gatewayInfo = {
        awsPropertyId,
        gwMacAddress: gateway?.basicInfo?.macAddress,
        gwId: whichId,
        gwPassword: whichPassword,
        gwIpAddress: gateway?.networkSettings?.ipV4Address
      } as IGatewayInfoPayload;
      const deviceInfo = {
        deviceMacAddress,
        deviceIpV4Address: device.ip_address,
        deviceSubnetMask: device.subnet_mask,
        deviceIpV4DefaultGateway: device.default_gateway,
        deviceName: device.station_name
      } as IDeviceInfoPayload;

      try {
        const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.ASSOCIATE, gatewayInfo, deviceInfo, 'static');
        if (!ioTPayload) {
          setError(t('Error_associating_device'));
          setIsLoading(false);
        }
        await handleGatewayCommand(ioTPayload);
        const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.ASSOCIATE, gatewayInfo, deviceInfo, 'static');
        const fetchResponse = await handleGatewayCommand(fetchPayload);
        if (gwCommandError || fetchResponse.error || !fetchResponse.data.statusCode.includes('200')) {
          setError(t('Error_associating_device'));
          setIsLoading(false);
        }
        updateDevice({
          device: {
            publicId: device.id,
            sitePublicId: dataDevice.sitePublicId,
            networkSettings: {
              ...dataDevice.networkSettings
            }
          }
        });

        const updatedDevice = {
          publicId: device.id,
          sitePublicId: dataDevice.sitePublicId,
          networkSettings: {
            ...dataDevice.networkSettings,
            ipV4Address: device.ip_address,
            subnetMask: device.subnet_mask,
            ipV4DefaultGateway: device.default_gateway,
            dns: device.dns
          }
        };

        dispatch(setDeviceNetworkSettings(updatedDevice));
        setIsLoading(false);
      } catch (error) {
        setError(t('Error_associating_device'));
      }
    },
    [t, setError, setIsLoading, updateDevice]
  );

  // Send requests for registration.
  const handleSaveAndContinue = React.useCallback(async () => {
    // First, get all rows
    const rows = apiRef.current?.getRowModels();

    // If all rows are valid, we continue on in this function
    // If any row is invalid, we throw an error
    for (const value of rows.values()) {
      const errorMessage = validateRow(value);
      if (errorMessage) {
        throw new Error(errorMessage);
      }
    }

    try {
      setIsSaving(true);

      // We need to make two arrays: one for all the devices to associate, and one for all the devices to save
      const devicesToAssociate = [];
      const devicesToSave: IDevicesToUpdate[] = [];
      const devicesToSkip = [];

      // Loop through each row, if the association status is 'ASSOCIATE', add it to the devicesToAssociate array
      // If the association status is 'SAVE', add it to the devicesToSave array. When adding to the devicesToSave array,
      // we need to make sure to create an IDevicesToAdd object, which is a subset of the IDevice object
      for (const value of rows.values()) {
        if (value.association_status === DeviceAssociationStatusEnum.ASSOCIATE) {
          devicesToAssociate.push(value);
          devicesToSave.push({
            publicId: value.id,
            basicInfo: {
              macAddress: value.mac_addr,
              stationNumber: value.station_number,
              stationName: value.station_name,
              firmwareVersion: value.firmware_version
            },
            networkSettings: {
              ipV4Address: value.ip_address,
              subnetMask: value.subnet_mask,
              ipV4DefaultGateway: value.default_gateway
            }
          });
        } else if (value.association_status === DeviceAssociationStatusEnum.SAVE) {
          devicesToSave.push({
            publicId: value.id,
            basicInfo: {
              macAddress: value.mac_addr,
              stationNumber: value.station_number,
              stationName: value.station_name,
              firmwareVersion: value.firmware_version
            },
            networkSettings: {
              ipV4Address: value.ip_address,
              subnetMask: value.subnet_mask,
              ipV4DefaultGateway: value.default_gateway
            }
          });
        } else if (value.association_status === DeviceAssociationStatusEnum.SKIP) {
          devicesToSkip.push(value);
        }
      }

      // If all devices are set to "Skip", skip to the next step
      if (devicesToSkip.length === rows.size) {
        setIsSaving(false);
        props.handleNextStep();
        return;
      }

      // Because the devicesToSave happens in a single batch call, we will count it as one step. This means at most,
      //   we will have devicesToAssociate.length + 1 steps if there are devices to save.
      setCountTotalSteps(devicesToAssociate.length + (devicesToSave.length > 0 ? 1 : 0));

      let localStepCounter = 1;
      // Associate devices
      // Go through each device in the devicesToAssociate array and associate it with the cloud
      // using the async function staticAssociation

      for (const device of devicesToAssociate) {
        setCurrentStep(localStepCounter);
        await staticAssociation(device);
        setRegistrationStatusMessage(
          `${t('Associating_Device')} ${device.station_name} ${device.mac_addr.toString()}...`
        );
        localStepCounter++;
      }

      // Save devices.
      // This is done in one single batch call
      const params = {
        sitePublicId: site.siteInfo.publicId,
        devices: devicesToSave
      };
      setCurrentStep(localStepCounter);
      setRegistrationStatusMessage(t('Now_Saving_Devices'));
      await batchUpdateDevices(params);

      // go to next step
      setCountTotalSteps(0);
      setCurrentStep(0);
      setRegistrationStatusMessage(undefined);
      setIsSaving(false);

      props.handleNextStep();
    } catch (error: any) {
      // Handle the error
      const stringError = error.toString();
      let friendlyErrorMessage = stringError;
      if (stringError.includes('device_basic_info_station_number_unique_per_site')) {
        friendlyErrorMessage = t('Duplicate_Station_Number_Detected');
      } else if (stringError.includes('device_basic_info_station_name_unique')) {
        friendlyErrorMessage = t('Duplicate_Station_Name_Detected');
      }
      setError(friendlyErrorMessage);
      setCountTotalSteps(0);
      setCurrentStep(0);
      setRegistrationStatusMessage(undefined);
      setIsSaving(false);
    }
  }, [setIsSaving, setCountTotalSteps, setCurrentStep, t]);

  const validateStationName = (stationName: string): boolean => {
    // If over 24 characters long or empty, this returns false
    return stationName.length > MAX_LENGTH || stationName.length === 0;
  };

  const validateStationNumber = (stationNumber: string): boolean => {
    // If not 3-24 characters, or a number, this returns false
    return stationNumber.length < 3 || stationNumber.length > MAX_LENGTH || !Number.isInteger(parseInt(stationNumber));
  };

  const isUnique = (rows: any, row: any, field: string): boolean => {
    for (const value of rows.values()) {
      if (value[field] === row[field] && value.id !== row.id) {
        return false;
      }
    }
    return true;
  };

  /**
   * @function validateRow
   * @description Returns a string if there is an error, otherwise returns null
   * @param {GridValidRowModel} row The row to validate
   * @return {string | null} Returns a string if there is an error, otherwise returns null
   */
  const validateRow = React.useCallback(
    (row: GridValidRowModel): string | null => {
      // Tracking the editing/focus state of the cell
      // We shall not allow the user to save the data if the cell is still in edit mode
      if (isCellOpenForRow(row.id)) {
        return t('There_are_unsaved_changes');
      }

      // If we skip the row, there is no need to validate the other fields in the row
      // NOTE: The above check is necessary because we do not want to allow rows with cells in edit mode to be able
      //  to validate the row.
      if (row.association_status === 'SKIP') {
        return null;
      }

      if (validateStationName(row.station_name)) {
        return t('Station_Name_requirements');
      }

      if (validateStationNumber(row.station_number)) {
        return t('Station_Number_min_length');
      }

      if (isNaN(Number(row.station_number))) {
        return t('Station_Number_must_be_a_number');
      }

      const rows = apiRef.current?.getRowModels();

      if (!isUnique(rows, row, 'station_number')) {
        return t('Station_Number_must_be_unique');
      }

      if (!isUnique(rows, row, 'ip_address')) {
        return t('IP_Address_Must_be_Unique');
      }

      if (!isUnique(rows, row, 'station_name')) {
        return t('Station_Name_must_be_unique');
      }

      if (!isValidIPV4Address(row.ip_address)) {
        return t('Invalid_IP_Address');
      }

      if (!isValidIPV4Address(row.subnet_mask)) {
        return t('Invalid_Subnet_Mask');
      }

      if (!isValidIPV4Address(row.default_gateway)) {
        return t('Invalid_Default_Gateway');
      }

      if (!isValidIPV4Address(row.dns)) {
        return t('Invalid_DNS');
      }

      // This indicates that no selection of "Associate", "Skip", or "Save" has been made
      if (row.association_status === 'TBD') {
        return t('Association_TBD');
      }

      // By default, return null if there are no errors
      return null;
    },
    [t]
  );

  // Initial Cell state validators

  const _initialMountCheckStationNumber = React.useCallback(
    (stationNumber: string | undefined, id: string) => {
      if (!stationNumber) {
        return null;
      }
      // Validate the string by checking if it is 3 digits or more,
      // less than 24 character, is a number, and is unique from the other station numbers
      if (stationNumber.length < 3) {
        return t('Minimum_3_Digits');
      } else if (stationNumber.length > MAX_LENGTH) {
        return t('Maximum_24_characters');
      } else if (isNaN(Number(stationNumber))) {
        return t('Station_Number_must_be_a_number');
      }
      const rows = apiRef.current?.getRowModels();
      let isUnique = true;
      for (const value of rows.values()) {
        if (value.station_number === stationNumber && value.id !== id) {
          isUnique = false;
          break;
        }
      }
      if (!isUnique) {
        return t('Must_be_unique');
      } else {
        return null;
      }
    },
    [t, apiRef]
  );

  const _initialMountCheckStationName = React.useCallback(
    (stationName: string | undefined, id: string) => {
      if (!stationName) {
        return null;
      }
      // Check if the station name is unique and if it is less than 24 characters
      if (stationName.length > MAX_LENGTH) {
        return t('Maximum_24_characters');
      } else if (stationName.length === 0) {
        return t('Station_Name_required');
      }

      // Check the regex station name
      if (!testIfValidStationName(stationName)) {
        return t('Invalid_Station_Name');
      }

      const rows = apiRef.current?.getRowModels();
      let isUnique = true;
      for (const value of rows.values()) {
        if (value.station_name === stationName && value.id !== id) {
          isUnique = false;
          break;
        }
      }
      if (!isUnique) {
        return t('Must_be_unique');
      } else {
        return null;
      }
    },
    [t, apiRef]
  );

  const _initialMountCheckIPAddress = React.useCallback(
    (ipAddress: string | undefined, id: string) => {
      if (!ipAddress) {
        return null;
      }

      // Check if the IP address is valid and if it is unique
      if (!testIfValidRegexIPV4(ipAddress)) {
        return t('Invalid_IP_Address');
      }

      const rows = apiRef.current?.getRowModels();
      let isUnique = true;
      for (const value of rows.values()) {
        if (value.ip_address === ipAddress && value.id !== id) {
          isUnique = false;
          break;
        }
      }
      if (!isUnique) {
        return t('Must_be_unique');
      } else {
        return null;
      }
    },
    [t, apiRef]
  );

  const _initialMountCheckSubnetMask = React.useCallback(
    (subnetMask: string | undefined) => {
      if (!subnetMask) {
        return null;
      }
      // Check if the subnet mask is valid
      if (!testIfValidRegexSubnetMask(subnetMask)) {
        return t('Invalid_Subnet_Mask');
      } else {
        return null;
      }
    },
    [t]
  );

  const _initialMountCheckDefaultGateway = React.useCallback(
    (defaultGateway: string | undefined) => {
      if (!defaultGateway) {
        return null;
      }
      // Check if the default gateway is valid
      if (!testIfValidRegexIPV4OrDNSGateway(defaultGateway)) {
        return t('Invalid_Default_Gateway');
      } else {
        return null;
      }
    },
    [t]
  );

  const _initialMountCheckDNS = React.useCallback(
    (dns: string | undefined) => {
      if (!dns) {
        return null;
      }
      // Check if the DNS is valid
      if (!testIfValidRegexIPV4OrDNSGateway(dns)) {
        return t('Invalid_DNS');
      } else {
        return null;
      }
    },
    [t]
  );

  const columns = React.useMemo(
    () => [
      {
        field: 'mac_addr',
        headerName: t('MAC_Address'),
        width: 160,
        editable: false,
        hideable: false,
        type: 'string',
        cellClassName: 'bold-text'
      } as GridColDef,
      {
        field: 'model_number',
        headerName: t('Model_Number'),
        width: 110,
        editable: false,
        hideable: false,
        type: 'string',
        cellClassName: 'bold-text'
      } as GridColDef,
      {
        field: 'device_image',
        headerName: t('Device'),
        align: 'center',
        hideable: true,
        headerAlign: 'center', // To match the centering of the image
        editable: false,
        display: 'flex',
        width: 150,
        cellClassName: 'flex justify-center items-center',
        renderCell: (params) => <img src={params.value} alt={params.value} style={{ maxWidth: 60, maxHeight: 40 }} />
      } as GridColDef,
      {
        field: 'station_name',
        headerName: t('Station_Name'),
        headerAlign: 'center',
        width: 150,
        editable: true,
        display: 'flex',
        align: 'center',
        hideable: false,
        type: 'string',
        cellClassName: 'cursor-pointer text-center station-name',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell {...props} error={_initialMountCheckStationName(props.value, props.row.id)} />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell {...props} error={_initialMountCheckStationName(props.value, props.row.id)} />
        ),
        preProcessEditCellProps: preProcessStationName,
        // Need to limit the length of the station name to 24 characters with maxLength
        maxLength: MAX_LENGTH,
        minLength: 1,
        valueFormatter: (params: GridValueFormatterParams) => {
          // Only allow VALID_STATION_NAME regex pattern
          return params.value.replace(VALID_STATION_NAME_REGEX, '');
        }
      } as GridColDef,
      {
        field: 'station_number',
        headerName: t('Station_Number'),
        headerAlign: 'center',
        width: 150,
        display: 'flex',
        align: 'center',
        editable: true,
        hideable: false,
        type: 'string',
        preProcessEditCellProps: preProcessStationNumber,
        cellClassName: 'cursor-pointer text-center',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell {...props} error={_initialMountCheckStationNumber(props.value, props.row.id)} />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell {...props} error={_initialMountCheckStationNumber(props.value, props.row.id)} />
        ),
        maxLength: MAX_LENGTH,
        minLength: 3,
        valueFormatter: (params: GridValueFormatterParams) => {
          // Only allow digits
          return params.value.replace(/\D/g, '');
        }
      } as GridColDef,
      {
        field: 'ip_address',
        headerName: t('IP_Address'),
        headerAlign: 'center',
        type: 'string',
        minWidth: 150,
        display: 'flex',
        editable: true,
        valueParser: (value: string) => {
          // Remove all non-numeric and non-dot characters
          return value.replace(/[^0-9.]/g, '');
        },
        valueFormatter: (params: GridValueFormatterParams) => {
          return formatAsIPAddress(params.value);
        },
        preProcessEditCellProps: preProcessIPAddress,
        align: 'center',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell {...props} error={_initialMountCheckIPAddress(props.value, props.row.id)} />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell {...props} error={_initialMountCheckIPAddress(props.value, props.row.id)} />
        ),
        cellClassName: 'text-center'
      } as unknown as GridColDef,
      {
        field: 'subnet_mask',
        type: 'string',
        headerName: t('Subnet_Mask'),
        headerAlign: 'center',
        editable: true,
        width: 150,
        valueParser: (value: string) => {
          // Remove all non-numeric and non-dot characters
          return value.replace(/[^0-9.]/g, '');
        },
        valueFormatter: (params: GridValueFormatterParams) => {
          return formatAsIPAddress(params.value);
        },
        preProcessEditCellProps: preProcessSubnetMask,
        display: 'flex',
        align: 'center',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell {...props} error={_initialMountCheckSubnetMask(props.value)} />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell {...props} error={_initialMountCheckSubnetMask(props.value)} />
        )
      } as unknown as GridColDef,
      {
        width: 150,
        field: 'default_gateway',
        type: 'string',
        headerName: t('Default_Gateway'),
        headerAlign: 'center',
        editable: true,
        display: 'flex',
        preProcessEditCellProps: preProcessDefaultGateway,
        valueParser: (value: string) => {
          // Remove all non-numeric and non-dot characters
          return value.replace(/[^0-9.]/g, '');
        },
        valueFormatter: (params: GridValueFormatterParams) => {
          return formatAsIPAddress(params.value);
        },
        align: 'center',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell {...props} error={_initialMountCheckDefaultGateway(props.value)} />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell {...props} error={_initialMountCheckDefaultGateway(props.value)} />
        )
      } as unknown as GridColDef,
      {
        field: 'dns',
        type: 'string',
        headerName: t('DNS'),
        headerAlign: 'center',
        editable: true,
        display: 'flex',
        width: 150,
        preProcessEditCellProps: preProcessDNS,
        valueParser: (value: string) => {
          // Remove all non-numeric and non-dot characters
          return value.replace(/[^0-9.]/g, '');
        },
        valueFormatter: (params: GridValueFormatterParams) => {
          return formatAsIPAddress(params.value);
        },
        align: 'center',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell {...props} error={_initialMountCheckDNS(props.value)} />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell {...props} error={_initialMountCheckDNS(props.value)} />
        )
      } as unknown as GridColDef,
      {
        // This is association/disassociation column
        field: 'association_status', // Should be set_device_id, but using this as a unique identifier
        headerName: t('Actions'),
        width: 150,
        editable: true,
        align: 'left',
        display: 'flex',
        hideable: false,
        type: 'singleSelect',
        preProcessEditCellProps: preProcessSelectAssociationStatus,
        valueOptions: (params) => {
          if (params.row.device_type === 18) {
            return [
              { value: 'TBD', label: t('Select_an_Option') },
              { value: 'SAVE', label: t('Button_Save') },
              { value: 'SKIP', label: t('Skip') }
            ];
          } else {
            return [
              { value: 'TBD', label: t('Select_an_Option') },
              { value: 'ASSOCIATE', label: t('Associate') },
              { value: 'SAVE', label: t('Button_Save') },
              { value: 'SKIP', label: t('Skip') }
            ];
          }
        }
      } as GridSingleSelectColDef
    ],
    [rows, apiRef, t]
  );

  const processRowUpdate = React.useCallback(
    (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
      // Check if the newRow is valid, according to our validation rules
      let isInvalid = validateRow(newRow);

      const rows = apiRef.current?.getRowModels();

      let canSubmitDevices = true;

      for (const value of rows.values()) {
        // Iterate and check if the current row that we are editing is valid
        if (value.id === newRow.id) {
          if (!isValidDevice(newRow)) {
            canSubmitDevices = false;

            // Make sure to update the state of this row
            isInvalid = 'Error detected';
            break;
          }
        } else if (!isValidDevice(value)) {
          canSubmitDevices = false;
          break;
        }
      }

      // Please keep this logic the way it is. It seems to "work just fine".
      // Simplified the logic to set the canSubmitDevices state
      setCanSubmit(canSubmitDevices);

      // Simplified the logic to set the "is_valid" state
      // The row is valid if the "isInvalid" variable is null (doesn't contain a string error message).
      return { ...oldRow, ...newRow, is_valid: !isInvalid };
    },
    [apiRef, validateRow, isValidDevice]
  );

  const loadGridRowsData = React.useCallback(() => {
    // Set the rows from the retrieved state
    const sessionKey = `wizard-setup-${site.siteInfo.publicId}`;
    const loadedRows = localStorage.getItem(sessionKey + '_rows');
    if (loadedRows) {
      const parsedRows = JSON.parse(loadedRows) as GridValidRowModel[];
      setRows(parsedRows);
      return parsedRows;
    } else {
      return [];
    }
  }, [site.siteInfo.publicId, setRows]);

  React.useEffect(() => {
    if (gatewayLoading) {
      setIsLoading(true);
    } else {
      // Load the grid rows data from local storage
      const cachedRows = loadGridRowsData();

      // Getting rid of duplicate rows based on MAC address
      const parsedRows = formatDevices(devices);
      // Remove all the rows with duplicate MAC addresses
      const uniqueRows = parsedRows.filter(
        (value, index, self) => self.findIndex((t) => t.mac_addr === value.mac_addr) === index
      );
      // Combine the cached rows with the unique rows from the network call, preferring cached rows
      // This allows the user to "pick up where they left off" if they navigate away from the page
      const combinedRows = [
        ...cachedRows,
        ...uniqueRows.filter((row) => !cachedRows.some((cachedRow) => cachedRow.mac_addr === row.mac_addr))
      ];
      setRows(combinedRows);

      setIsLoading(false);
    }
  }, [devices, gatewayLoading, loadGridRowsData]);

  React.useEffect(() => {
    const updateMaxLength = () => {
      const inputElements = dataGridRef.current?.querySelectorAll('input.station-name');
      inputElements?.forEach((input) => {
        input.setAttribute('maxLength', '24');
      });
    };

    updateMaxLength();
  }, []);

  // For managing cached DataGrid state
  /**
   * Load the grid state from the local storage
   * Strictly a state restoration function
   */
  const loadGridState = React.useCallback(() => {
    const sessionKey = `wizard-setup-${site.siteInfo.publicId}`;
    const loadedState = localStorage.getItem(sessionKey);

    if (loadedState) {
      const parsedState = JSON.parse(loadedState) as GridInitialState;
      setInitialState(parsedState);
      return parsedState;
    } else {
      return {};
    }
  }, [site.siteInfo.publicId]);

  const saveDataGridState = React.useCallback(() => {
    const exportedState = apiRef.current?.exportState();
    if (exportedState) {
      const sessionKey = `wizard-setup-${site.siteInfo.publicId}`;
      // Keep a key to identify the data for the session
      localStorage.setItem(sessionKey, JSON.stringify(exportedState));
      return exportedState;
    } else {
      return {};
    }
  }, [apiRef, site.siteInfo.publicId]);

  const saveGridRowsData = React.useCallback(() => {
    const currentRowValues = apiRef.current?.getRowModels();
    if (currentRowValues) {
      const rowsArray: GridValidRowModel[] = [];
      // Iterate rows and set the state data
      for (const value of currentRowValues.values()) {
        rowsArray.push(value);
      }
      if (rowsArray.length === 0) {
        return [];
      }
      setRows(rowsArray);
      // Save to local storage
      const sessionKey = `wizard-setup-${site.siteInfo.publicId}`;
      localStorage.setItem(sessionKey + '_rows', JSON.stringify(rowsArray));
      return rowsArray;
    } else {
      return [];
    }
  }, [apiRef, site.siteInfo.publicId, setRows]);

  React.useLayoutEffect(() => {
    loadGridState();
    loadGridRowsData();
    // restoreGridState();
    // Set the rows from the retrieved state

    // Handle refresh and navigating away/refreshing the page
    window.addEventListener('beforeunload', saveDataGridState);
    window.addEventListener('beforeunload', saveGridRowsData);

    // Handle back and forward navigation
    window.addEventListener('popstate', saveDataGridState);
    window.addEventListener('popstate', saveGridRowsData);

    // Cleanup
    return () => {
      // Handle hard refresh
      window.removeEventListener('beforeunload', saveDataGridState);
      window.removeEventListener('beforeunload', saveGridRowsData);

      // Handle back and forward navigation
      window.removeEventListener('popstate', saveDataGridState);
      window.removeEventListener('popstate', saveGridRowsData);
      saveDataGridState();
    };
  }, [loadGridRowsData, loadGridState, saveDataGridState, saveGridRowsData]);

  if (isSaving) {
    return (
      <LoadingProgressStepCounter
        currentStep={currentStep}
        totalSteps={countTotalSteps}
        message={registrationStatusMessage}
      />
    );
  } else if (isLoading || gwLoading) {
    return <Spinner />;
  } else {
    // Note: There will be an inspection error for "xxl" not being a valid Breakpoint value because it is not defined in
    // the Breakpoint enum. This is a known issue, and we can safely ignore it because we need this "xxl" value.
    return (
      <Container maxWidth={'xl'} sx={{ width: '90%' }}>
        <Box className={'flex flex-col my_20px'}>
          <Box className={'flex flex-col'}>
            <h2>{t('Assign_Station_Names')}</h2>
            <p>{t('Review_and_Make_Adjustments')}</p>
            <DataGrid
              initialState={{ ...initialState }}
              apiRef={apiRef}
              ref={dataGridRef}
              processRowUpdate={processRowUpdate}
              columns={columns}
              rows={rows}
              getRowClassName={(params) => {
                if (params.row.is_valid) {
                  return 'row-success';
                } else {
                  return '';
                }
              }}
              columnVisibilityModel={{
                id: false,
                is_valid: false,
                firmware_version: false,
                device_type: false
              }}
              cellModesModel={cellModesModel}
              onCellModesModelChange={handleCellModesModelChange}
              onCellClick={handleCellClick}
              getRowId={(row) => row.id}
            />
          </Box>
          <Box className={'flex flex-row justify-between items-center w-full mt-2'}>
            <Button onClick={props.handlePreviousStep} variant={'contained'} color={'primary'}>
              {t('Button_Back')}
            </Button>
            {error && <span className={'text-red text-center text-sm mx_24px'}>{error}</span>}
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={async () => await handleSaveAndContinue()}
              disabled={!canSubmit}
            >
              {t('Continue')}
            </Button>
          </Box>
        </Box>
      </Container>
    );
  }
};

export default DeviceConfigurationGrid;
