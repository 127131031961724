import React, { FC, useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  Divider,
  IconButton,
  Checkbox
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { Container } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { updateSite as updateSiteInStore, RootState } from 'store';
import { updateSite } from 'shared/api/Aws/awsApi';
import {
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useHandleGatewayCommandMutation,
  useUpdateSyncedDateMutation,
  useGetDeviceWithPublicIdQuery
} from 'services/aiphoneCloud';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import Countdown from 'shared/utils/CountDown';
import { RegisterGatewayForm } from './components/forms/RegisterGatewayForm';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import SnackbarAlert from '../../../../../shared/components/SnackbarAlert';
import { isApiError } from 'shared/api/ApiError';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import ResetInstructions from './components/dialogs/ResetInstructions';

interface IRegisterGatewayProps {
  isRegistered: boolean;
  setIsRegistered: (isRegistered: boolean) => void;
  handlePreviousStep: () => void;
  handleNextStep: () => void;
}

interface ICreateDeviceRes {
  data?: {
    macAddress: string;
    publicId: string;
  }[];
  error?: FetchBaseQueryError | SerializedError;
}
export interface IRegisterGatewayWithCloud {
  macAddress: string;
}

type RegisterGatewayResponseMessageType = 'TIMEOUT' | 'ERROR';

// Potential errors:

const isCustomError = (error: unknown): error is { message: string } => {
  return typeof error === 'object' && error !== null && 'message' in error;
};

const RegisterGateway: FC<IRegisterGatewayProps> = ({
  isRegistered,
  setIsRegistered,
  handlePreviousStep,
  handleNextStep
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [updateSyncedDate] = useUpdateSyncedDateMutation();
  /*Operations with root state*/
  const awsPropertyId = useSelector((state: RootState) => state.site?.siteInfo?.awsPropertyId) || '0';
  const site = useSelector((state: RootState) => state.site);
  const sitePublicId = useSelector((state: RootState) => state.site?.siteInfo?.publicId);
  const gateway = useSelector((state: RootState) => state.site?.siteInfo?.registeredGatewayPublicId || '');
  const gatewayDevice = useGetDeviceWithPublicIdQuery(gateway);
  const [sendingSearch, setSendingSearch] = useState(false);
  const [gatewayType, setGatewayType] = useState(14);
  const [gwMacAddr, setGwMacAddr] = useState('');
  const [alertErrorMessageType, setAlertErrorMessageType] = React.useState<RegisterGatewayResponseMessageType>('ERROR');

  /*Operations with local state*/
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSkipDialogOpen, setIsSkipDialogOpen] = useState(false);

  const [isGatewayPasswordForgotten, setIsGatewayPasswordForgotten] = useState(false);
  const [showIdPasswordFields, setShowIdPasswordFields] = useState(false);
  const [customerCredentials, setCustomerCredentials] = useState({ gwId: '', gwPassword: '' });

  const handleGwIdPwChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCustomerCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value
    }));
  };

  const handleGwPwSubmit = () => {
    // Handle the submission of the user's ID and password
    setShowIdPasswordFields(false);
    registerGatewayWithCloud({ macAddress: gwMacAddr });
  };

  // communicating dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleClose = () => {
    setIsDialogOpen(false);
    setIsGatewayPasswordForgotten(false);
    setShowIdPasswordFields(false);
  };

  const getSerialNumber = (macAddress: string) => {
    const FNV_PRIME_64 = BigInt('0x100000001b3');
    const FNV_OFFSET_BASIS_64 = BigInt('14695981039346656037');

    let hash = FNV_OFFSET_BASIS_64;

    for (let i = 0; i < macAddress.length; i++) {
      hash ^= BigInt(macAddress.charCodeAt(i));
      hash = (hash * FNV_PRIME_64) % BigInt('0x10000000000000000'); // Ensure 32-bit overflow
    }

    return hash.toString(16); // Return hash as hexadecimal string
  };

  /*API requests*/
  const [updateDevice] = useUpdateDeviceMutation();
  const [createDevice] = useCreateDeviceMutation();
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();

  /*Helpers*/
  const strings = {
    title: 'Register Gateway',
    body1:
      'To manage intercom systems remotely, the IXGW-(T)GW Cloud Gateway must be registered with Remote Management using the steps below.',
    step1Title:
      '1. Connect the IXGW-(T)GW gateway to a network with internet access. Disregard this step when only using SIM connection for data..',
    step1Body:
      'Connect the IXGW-(T)GW Gateway to the network using an Ethernet cable to a Power over Ethernet switch port. The Gateway will automatically obtain an IP address from the DHCP server. If the network does not have DHCP, connect it to a network that does and then manually assign a static IP address to the Gateway during the Device Network Info step later in this wizard.',
    step2Title: '2. Register the Gateway with Remote Management by entering the IXGW-(T)GW MAC address below.',
    step2Body: 'The MAC address can be found on a yellow label on the bottom of the Gateway.',
    backButtonText: 'Back',
    skipButtonText: 'Skip',
    continueButtonText: 'Continue',
    registeringGateway: 'Registering Gateway...',
    gatewayRegistered: 'Gateway registered successfully!',
    defaultErrorMessage: t('New_Site_Wizard.Register_Gateway.Default_Error_Message'),
    gatewayRegisteredBut: 'Gateway registered successfully, but the Station Search failed. Please try again.',
    gatewayAuthError: 'Gateway authentication failed. Please try again.'
  };

  useEffect(() => {
    if (gatewayDevice) {
      if (!gatewayDevice.data?.basicInfo?.macAddress) {
        return;
      }
      const macAddress = gatewayDevice.data?.basicInfo?.macAddress;
      // Fixing ignored promise resolution
      updateGatewayInformationFromSearch(macAddress)
        .then(() => {
          setIsLoading(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [gatewayDevice]);

  const updateGatewayInformationFromSearch = async (macAddress: string) => {
    // Step 4 - Station Search and fetch the gateway details
    let fetchResponse, ioTPayload, fetchPayload;

    if (sendingSearch) {
      return;
    } else {
      setSendingSearch(true);
      setIsLoading(true);
      setIsDialogOpen(true);
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }

    const systemId = site.siteInfo?.systemId;
    const systemPassword = site.siteInfo?.systemPassword;
    const gwId = customerCredentials.gwId || systemId || 'admin';
    const gwPassword = customerCredentials.gwPassword || systemPassword || 'admin';

    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: macAddress,
      gwId: gwId,
      gwPassword: gwPassword
    };

    try {
      ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.STATION_SEARCH, gatewayInfo, null, null);
      fetchResponse = await handleGatewayCommand(ioTPayload).unwrap();

      await new Promise((resolve) => setTimeout(resolve, 10000));
      // fetch response from dynamoDB
      fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.STATION_SEARCH, gatewayInfo, null, null);
      fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

      const registerGWResponse = fetchResponse.payload.find(
        (device: { mac_addr: string }) => device.mac_addr === macAddress
      );
      const gwFirmwareVersion = registerGWResponse.fw_ver;
      const communicationMethod = gatewayType === 14 ? 3 : 1;
      const serial = getSerialNumber(macAddress);

      if (registerGWResponse?.ip_addr && registerGWResponse?.ip_subnet && registerGWResponse?.ip_gateway && serial) {
        // check to see if the device exists in the database
        const needsNewDevice =
          site.siteInfo?.registeredGatewayPublicId === '' || site.siteInfo?.registeredGatewayPublicId === null;
        if (needsNewDevice) {
          // step 3 - update the site with the registeredGatewayPublicId
          const deviceCreationPayload = {
            sitePublicId: sitePublicId,
            devices: [
              {
                deviceType: 18,
                deviceModel: gatewayType,
                macAddress: macAddress,
                stationNumber: '9999',
                stationName: 'Cloud Gateway'
              }
            ]
          };
          const response: ICreateDeviceRes = await createDevice(deviceCreationPayload);
          const gatewayPublicId = response[0]?.publicId || response?.data[0]?.publicId;
          if (!response?.data || response?.error) {
            throw { message: 'Failed to add new gateway device in the database' };
          }

          dispatch(updateSiteInStore({ registeredGatewayPublicId: response?.data[0]?.publicId }));
          const updateSitePayload = {
            siteData: {
              ...site.siteInfo,
              awsPropertyId: site.siteInfo?.awsPropertyId || '',
              registeredGatewayPublicId: gatewayPublicId
            },
            sitePublicId: sitePublicId
          };
          dispatch(updateSiteInStore(updateSitePayload));
          await updateSite(updateSitePayload);
          updateDevice(updatedDeviceParams);
        }

        // now update the device with the new information from search
        const updatedDeviceParams = {
          device: {
            publicId: site.siteInfo?.registeredGatewayPublicId,
            sitePublicId: sitePublicId,
            basicInfo: {
              firmwareVersion: gwFirmwareVersion,
              adminId: customerCredentials.gwId || 'admin',
              adminPass: customerCredentials.gwPassword || 'admin'
            },
            networkSettings: {
              ipV4Address: registerGWResponse?.ip_addr,
              subnetMask: registerGWResponse?.ip_subnet,
              ipV4DefaultGateway: registerGWResponse?.ip_gateway,
              ipV4PrimaryDns: '8.8.8.8'
            },
            gatewaySettings: {
              gatewaySerialNumber: serial,
              communicationMethod: communicationMethod
            }
          }
        };
        updateDevice(updatedDeviceParams);

        if (customerCredentials.gwId) {
          const params = {
            device: {
              publicId: site.siteInfo?.registeredGatewayPublicId
            }
          };
          updateSyncedDate(params);
        }

        setIsRegistered(true);
      }
    } catch (error) {
      if (gateway) {
        setIsLoading(false);
        setIsDialogOpen(false);
        setSendingSearch(false);
        setErrorMessage(t('Gateway_Registered_already'));
        setAlertErrorMessageType('TIMEOUT');
      } else {
        setIsLoading(false);
        setIsDialogOpen(false);
        setSendingSearch(false);
        setErrorMessage(strings.defaultErrorMessage);
      }
    } finally {
      setSendingSearch(false);
      setIsDialogOpen(false);
    }
  };

  const registerGatewayWithCloud = async (values: IRegisterGatewayWithCloud) => {
    let fetchPayload, fetchResponse;
    if (values.macAddress) {
      setGwMacAddr(values.macAddress);
    }
    const gwMacAddress = values.macAddress || gwMacAddr;
    const systemId = site.siteInfo?.systemId;
    const systemPassword = site.siteInfo?.systemPassword;

    if (!site.siteInfo.awsPropertyId) {
      setErrorMessage(t('Error_Missing_Property_ID'));
      return;
    }

    setIsLoading(true);
    setIsDialogOpen(true);
    setSendingSearch(false);

    // Step 1: register Gateway
    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gwMacAddress,
      gwId: customerCredentials.gwId || systemId || 'admin',
      gwPassword: customerCredentials.gwPassword || systemPassword || 'admin'
    };
    // prepare payload for gateway registration
    const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.REGISTER, gatewayInfo, null, null);
    // this should never fail to reach our backend
    // if the command succeeds, the gateway will stop listening to the queue.
    await handleGatewayCommand(ioTPayload).unwrap();

    try {
      // fetch the result from the gateway
      fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.REGISTER, gatewayInfo, null, null);

      fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
      if (fetchResponse.error) {
        let defaultErrorMessage = strings.defaultErrorMessage;
        if ('status' in fetchResponse.error && fetchResponse.error.status === 404) {
          defaultErrorMessage = 'Gateway did not respond.';
        }
        throw { message: defaultErrorMessage };
      }
      if (!fetchResponse || fetchResponse.length === 0) {
        throw { message: strings.defaultErrorMessage };
      }
      const statusCode = fetchResponse?.statusCode.slice(0, 3);
      if (statusCode === '200') {
        // Step 2. Once the register is successful, create the device in the Cloud DB
        // Only create the device for gateway if it is not already registered
        const needsNewDevice =
          site.siteInfo?.registeredGatewayPublicId === '' || site.siteInfo?.registeredGatewayPublicId === null;
        if (needsNewDevice) {
          const deviceCreationPayload = {
            sitePublicId: sitePublicId,
            devices: [
              {
                deviceType: 18,
                deviceModel: gatewayType,
                macAddress: gwMacAddress,
                stationNumber: '9999',
                stationName: 'Cloud Gateway'
              }
            ]
          };
          const response: ICreateDeviceRes = await createDevice(deviceCreationPayload);
          const gatewayPublicId = response[0]?.publicId || response?.data[0]?.publicId;
          if (!response?.data || response?.error) {
            throw { message: 'Failed to add new gateway device in the database' };
          }
          // step 3 - update the site with the registeredGatewayPublicId

          dispatch(updateSiteInStore({ registeredGatewayPublicId: response?.data[0]?.publicId }));
          const updateSitePayload = {
            siteData: {
              ...site.siteInfo,
              awsPropertyId: site.siteInfo?.awsPropertyId || '',
              registeredGatewayPublicId: gatewayPublicId
            },
            sitePublicId: sitePublicId
          };
          dispatch(updateSiteInStore(updateSitePayload));
          await updateSite(updateSitePayload);
        } else {
          updateGatewayInformationFromSearch(gwMacAddress);
        }
      } else if (statusCode === '420') {
        /** TODO: if the error is 420, prompt user to register with the custom id and password */
        setShowIdPasswordFields(true);
        // If the gateway responded (was listening to the queue) but rejected the request, show an error.
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setIsDialogOpen(false);
        throw { message: strings.defaultErrorMessage };
      }
    } catch (error) {
      // The Gateway might have successfully registered but the device creation failed
      let defaultErrorMessage = strings.defaultErrorMessage;
      if (error.message === 'Gateway authentication failed. Please try again.') {
        setIsDialogOpen(false);
        setErrorMessage(t('Gateway_Authentication_Failed'));
        return;
      }

      if (error.data.errorCode === 'NO_PAYLOAD_FOUND') {
        // the gateway may not be listening to the queue, so let's try sending a search command
        await updateGatewayInformationFromSearch(gwMacAddress);
        setIsDialogOpen(false);
        setErrorMessage(strings.defaultErrorMessage);
        return;
      }

      if (isApiError(error)) {
        defaultErrorMessage = `${error}`;
      }
      if (isCustomError(error)) {
        defaultErrorMessage = error.message;
      }
      setIsDialogOpen(false);
      setErrorMessage(defaultErrorMessage);
      setIsLoading(false);
      setIsRegistered(false);
    }
  };

  return (
    <Container maxWidth="md">
      <SnackbarAlert
        type={alertErrorMessageType === 'TIMEOUT' ? 'info' : 'error'}
        time={7000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <Grid container spacing={2}></Grid>
      <Box>
        <Box sx={styles.centerContent}>
          <Typography variant="h4" sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold' }}>
            {strings.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {strings.body1}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {strings.step1Title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {strings.step1Body}
          </Typography>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {strings.step2Title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            {strings.step2Body}
          </Typography>
        </Box>
        <Box sx={styles.centerContent}>
          {isDialogOpen && (
            <Dialog open={isDialogOpen} onClose={handleClose} fullWidth maxWidth="sm" sx={styles.centerContent}>
              <DialogActions sx={styles.dialogAction}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </DialogActions>
              {isGatewayPasswordForgotten && <ResetInstructions />}
              {!showIdPasswordFields && !isGatewayPasswordForgotten && (
                <Grid sx={styles.innerContent}>
                  <DialogTitle variant="h4" sx={styles.dialogTitle}>
                    {!showIdPasswordFields && gateway ? 'Communicating with Gateway' : 'Registering Gateway'}
                  </DialogTitle>
                  <Box sx={styles.separator}>
                    <LoadingButton loading={true} loadingIndicator={<CircularProgress size="40px" color="primary" />} />
                  </Box>
                  {gateway ? (
                    <Typography variant="caption" textAlign="center">
                      Please wait while the Gateway searches for stations.
                    </Typography>
                  ) : (
                    <Typography variant="caption" textAlign="center">
                      Please wait for the Gateway to respond to this request.
                    </Typography>
                  )}
                </Grid>
              )}

              {showIdPasswordFields && !isGatewayPasswordForgotten && (
                <Grid sx={styles.innerContent}>
                  <Typography variant="h6" sx={{ mb: 3 }}>
                    {t('Gateway_ID_PW_Retry')}
                  </Typography>
                  <Formik initialValues={{ gwId: '', gwPassword: '' }} onSubmit={handleGwPwSubmit}>
                    <Form>
                      <Grid>
                        <Field
                          type="text"
                          name="gwId"
                          value={customerCredentials.gwId}
                          onChange={handleGwIdPwChange}
                          placeholder="Gateway ID"
                          style={styles.inputField}
                        />
                      </Grid>
                      <Grid>
                        <Field
                          type="password"
                          name="gwPassword"
                          value={customerCredentials.gwPassword}
                          onChange={handleGwIdPwChange}
                          placeholder="Gateway password"
                          style={styles.inputField}
                        />
                      </Grid>
                      <Grid>
                        <Checkbox
                          checked={isGatewayPasswordForgotten}
                          onChange={() => setIsGatewayPasswordForgotten(true)}
                        />
                        <Typography variant="caption" textAlign="center">
                          {t('Gateway_ID_PW_Unknown')}
                        </Typography>
                      </Grid>
                      <Grid container sx={styles.buttonContainer}>
                        <LoadingButton
                          loading={false}
                          loadingIndicator={<CircularProgress size="20px" />}
                          variant="contained"
                          color="primary"
                          sx={{ mb: 3 }}
                          type="submit"
                        >
                          {t('Try_Again')}
                        </LoadingButton>
                      </Grid>
                    </Form>
                  </Formik>
                </Grid>
              )}
            </Dialog>
          )}
          <Paper sx={styles.registerOptions}>
            {isRegistered ? (
              <Box sx={styles.centerContent}>
                <Typography variant="h6" sx={{ mb: 4 }}>
                  {strings.gatewayRegistered}
                </Typography>
                <Countdown onComplete={() => handleNextStep()} />
                <CheckCircleIcon sx={{ fontSize: '8rem', color: 'primary.main' }} />
              </Box>
            ) : (
              <RegisterGatewayForm
                onSubmitCallback={registerGatewayWithCloud}
                awsPropertyId={`${awsPropertyId}`}
                isLoading={isLoading}
                gatewayType={gatewayType}
                setGatewayType={setGatewayType}
              />
            )}
            <Divider sx={{ width: '100%' }} />
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="h6" sx={{ mb: 1 }} align="center">
                Troubleshooting
              </Typography>
              <List>
                <ListItem>
                  - When a factory default gateway powers on it joins the AiphoneCloud Registration queue for 15
                  minutes. If your IXGW-(T)GW has been powered for more than 15 minutes, power cycle the device, wait
                  for a solid light, and try again.
                </ListItem>
                <ListItem>
                  - If you are having further issues, refer to this article for additional troubleshooting steps.
                  <br />
                </ListItem>
                <Typography sx={{ ml: 2 }}>
                  <a href="https://www.aiphone.com/kbtopic/ixg-series" target={'_blank'}>
                    QuikTech article
                  </a>
                </Typography>
              </List>
            </Box>
          </Paper>
        </Box>
        <Box sx={styles.dualButtonContainer}>
          <Button variant="contained" color="primary" onClick={handlePreviousStep}>
            {strings.backButtonText}
          </Button>
          <Button color="primary" onClick={() => setIsSkipDialogOpen(true)}>
            {strings.skipButtonText}
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={!isRegistered} onClick={handleNextStep}>
            {strings.continueButtonText}
          </Button>
        </Box>
      </Box>
      <Dialog
        open={isSkipDialogOpen}
        onClose={() => setIsSkipDialogOpen(false)}
        aria-labelledby={'Skip gateway registration'}
      >
        <DialogTitle>Skip gateway registration?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you skip gateway registration, you will not be able to manage your intercoms remotely. Are you sure you
            want to skip?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSkipDialogOpen(false)}>Cancel</Button>
          <Button onClick={() => handleNextStep()}>Skip Gateway Registration</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 1
  },
  innerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 3
  },
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    height: '100%',
    marginTop: 1
  },
  gwImage: {
    height: '25%',
    width: '25%',
    marginTop: 1
  },
  iconButton: {
    position: 'relative',
    top: -25,
    left: 0
  },
  qrScannerButton: {
    fontSize: '7rem',
    color: 'primary.main',
    '&:hover': {
      color: 'primary.dark'
    }
  },
  separator: {
    height: '100%',
    display: 'flex',
    marginBottom: '40px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  registerOptions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    p: 3,
    px: 5,
    mb: 4,
    width: '100%',
    height: 'auto'
  },
  inputField: {
    borderRadius: '8px',
    padding: '15px',
    margin: '15px',
    border: '1px solid #ccc'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    p: 3
  },
  dialogTitle: {
    mb: 3,
    color: 'primary.main',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center'
  },
  dialogAction: {
    justifyContent: 'flex-end'
  }
};

export default RegisterGateway;
